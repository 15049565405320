// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type {
  Nullable,
  OrbitApiMeta,
  PrimeHour,
  Session
} from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

type sessionType = 'live' | 'sessions' | 'upcoming'

export const useSessionStore = defineStore('session', () => {
  // fetch repositories
  const { $orbitApiFetch, $sessions } = useNuxtApp()

  // state
  const session = ref<Nullable<Session>>(null)
  const sessions = ref<Session[]>([])
  const liveSession = ref<Nullable<Session>>(null)
  const liveSessions = ref<Session[]>([])
  const upcomingSessions = ref<Session[]>([])
  const primeHours = ref<PrimeHour[]>([])

  // actions
  async function index (
    config?: NitroFetchOptions<string>,
    stateProperty: sessionType = 'sessions'
  ) {
    const { sessions: _sessions } =
      await $sessions.index<{ sessions: Session[], meta: OrbitApiMeta}>(config)

    switch (stateProperty) {
      case 'live':
        liveSessions.value = _sessions || []
        break
      case 'sessions':
        sessions.value = _sessions || []
        break
      case 'upcoming':
        upcomingSessions.value = _sessions || []
        break
    }
    return _sessions
  }

  async function show (
    id: number | string,
    config?: NitroFetchOptions<string>
  ) {
    const { session: _session } =
      await $sessions.show<{ session: Session }>(id, config)
    session.value = _session
    return session
  }

  async function connect (
    id: number | string,
    config?: NitroFetchOptions<string>
  ) {
    const { session: _session } =
      await $orbitApiFetch<{ session: Session }>(
        `/v2/sessions/${id}/connect`,
        config
      )
    liveSession.value = _session
    return _session
  }

  async function getPrimeHours (
    config?: NitroFetchOptions<string>
  ) {
    const { days } =
      await $orbitApiFetch<{ days: PrimeHour[] }>(
        '/v2/sessions/prime_hours',
        config
      )
    primeHours.value = days
    return days
  }

  return {
    connect,
    getPrimeHours,
    index,
    liveSession,
    liveSessions,
    primeHours,
    session,
    sessions,
    show,
    upcomingSessions
  }
})
